import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Such kind. Very wow.`}</h2>
    <p>{`Thank you for considering donating. Every dollar helps me to continue creating free and open-sourced software, blog content, music, and whatever else comes out of my noggin. `}</p>
    <h3>{`Venmo`}</h3>
    <p>{`@ewich`}</p>
    <h3>{`PayPal`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      